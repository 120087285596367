import loadable from '@loadable/component';
import { Button } from '@simplywallst/ui-core';
import If from '@/components/If';
import { FormContainer } from './styled';
import { Translation } from '@/hooks/useTranslation';

const RegistrationForm = loadable(
  () => import('./components/RegistrationForm')
);
const LoginForm = loadable(() => import('./components/LoginForm'));
const ContinueWithButtons = loadable(
  () => import('./components/ContinueWithButtons')
);

const OAuthIconButtons = loadable(() => import('./components/OAuthButtons'), {
  resolveComponent: (component) => component.OAuthButtons,
});

export type Method = 'oauth' | 'login' | 'register';

interface Props {
  current?: Method;
  onChangeType?: (type: Method) => void;
  onRegisterSuccess?: () => void;
  onLoginSuccess?: () => void;
  /**
   * Set redirect URL for once post-verification registration has occurred.
   */
  postVerifyRedirectTo?: string;
  ticker?: string;
  /**
   * Provide an email to prefill the login with.
   * Allows a streamlined login experience for known user returns.
   */
  prefillEmail?: string;
  /**
   * Whether to focus the email input on mount.
   *
   * If a `prefillEmail` is provided, the password input instead will be focused.
   */
  focusOnMount?: boolean;
}

export const AuthenticationSelect = ({
  onRegisterSuccess = () => null,
  onLoginSuccess = () => null,
  onChangeType,
  current = 'login',
  postVerifyRedirectTo,
  ticker,
  prefillEmail,
  focusOnMount = false,
}: Props) => {
  return (
    <If
      conditions={{
        oauth: current === 'oauth',
        loginRegister: current !== 'oauth',
      }}
    >
      {{
        oauth: () => (
          <ContinueWithButtons
            onLoginSuccess={onLoginSuccess}
            onRegisterSuccess={onRegisterSuccess}
            onClickRegister={() => {
              if (typeof onChangeType === 'function') {
                onChangeType('register');
              }
            }}
            onClickSignIn={() => {
              if (typeof onChangeType === 'function') {
                onChangeType('login');
              }
            }}
            ticker={ticker}
          />
        ),
        loginRegister: () => (
          <>
            <FormContainer>
              <If
                conditions={{
                  signIn: current === 'login',
                  register: current === 'register',
                }}
              >
                {{
                  signIn: () => (
                    <LoginForm
                      prefillEmail={prefillEmail}
                      focusOnMount={focusOnMount}
                      onSuccess={onLoginSuccess}
                    />
                  ),
                  register: () => (
                    <RegistrationForm
                      postVerifyRedirectTo={postVerifyRedirectTo}
                      onRegister={onRegisterSuccess}
                    />
                  ),
                }}
              </If>
            </FormContainer>
            {current === 'login' || current === 'register' ? (
              <OAuthIconButtons
                type={current}
                onLoginSuccess={onLoginSuccess}
                onRegisterSuccess={onRegisterSuccess}
              />
            ) : null}
            {current === 'login' && (
              <p>
                <Translation i18nKey="app.modal.registerInstead">
                  Not a member?
                  <Button
                    styleType="strongLink"
                    onClick={() => onChangeType?.('register')}
                    data-cy-id="toggle-register"
                  >
                    Sign up
                  </Button>
                </Translation>
              </p>
            )}
            {current === 'register' && (
              <p>
                <Translation i18nKey="app.modal.registration.loginCTA">
                  Already have an account?
                  <Button
                    styleType="strongLink"
                    onClick={() => onChangeType?.('login')}
                    data-cy-id="toggle-login"
                  >
                    Sign in
                  </Button>
                </Translation>
              </p>
            )}
          </>
        ),
      }}
    </If>
  );
};
