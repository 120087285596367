import { useEffect, useState } from 'react';
import { Modal } from '@simplywallst/ui-core';
import { AuthenticationSelect } from '@/components/AuthenticationSelect';
import { useIntersectionObserverDisabler } from '@/context/IntersectionObserverDisabler';
import loadable from '@loadable/component';
import { ActionsPanel } from './components/ActionsPanel';
import { SplashPanelRegister } from './components/SplashPanelRegister';
import { SplashPanelSignIn } from './components/SplashPanelSignIn';
import type { Method } from '@/components/AuthenticationSelect';
import { useSummaryV2 } from '@/hooks/useCompany';
import { CloseIcon } from './components/CloseIcon';
import { cn } from '@simplywallst/ui-core/next';

const CareersUpsell = loadable(() => import('@components/CareersUpsell'), {
  resolveComponent: (component) => component.CareersUpsell,
});

interface Props {
  canClose?: boolean;
  removeWindowScroll?: boolean;
  initialType?: Method;
  postVerifyRedirectTo?: string;
  SplashPanel?: {
    Component: React.ComponentType;
    isDark?: boolean;
  };
  /**
   * Provide an email to prefill the login with.
   * Allows a streamlined login experience for known user returns.
   */
  prefillEmail?: string;
  /**
   * Whether to focus the login form inputs on mount.
   */
  focusOnMount?: boolean;
  onRegisterSuccess?: () => void;
  onLoginSuccess?: () => void;
  onClose?: () => void;
}

export const GuestRegistrationModal = ({
  initialType = 'oauth',
  removeWindowScroll = false,
  onClose = () => undefined,
  canClose = false,
  postVerifyRedirectTo,
  SplashPanel,
  prefillEmail,
  focusOnMount = false,
  onRegisterSuccess,
  onLoginSuccess,
}: Props) => {
  const [type, setType] = useState(initialType);
  const [showCareersUpsell, setShowCareersUpsell] = useState(false);
  const intersectionObserverDisabler = useIntersectionObserverDisabler();
  const { tickerSymbol } = useSummaryV2();

  useEffect(() => {
    if (typeof intersectionObserverDisabler.setEnabled === 'function') {
      /** we'll turn off intersection observer IF modal is shown */
      intersectionObserverDisabler.setEnabled(false);
    }
    return () => {
      if (typeof intersectionObserverDisabler.setEnabled === 'function') {
        intersectionObserverDisabler.setEnabled(true);
      }
    };
  }, [intersectionObserverDisabler]);

  // Determine which panel to show
  const PanelToRender =
    SplashPanel?.Component ||
    (type === 'oauth' ? SplashPanelRegister : SplashPanelSignIn);

  return (
    <>
      <Modal
        onTamper={() => setShowCareersUpsell(true)}
        isNaked
        asForm
        blurTargetId={'root'}
        removeWindowScroll={removeWindowScroll}
        onClose={onClose}
      >
        <div className="grid place-items-center">
          <div
            className="relative grid w-full max-w-96 grid-cols-1 overflow-hidden rounded-x1 lg:max-w-[877px] lg:grid-cols-[7fr_5fr]"
            data-cy-id="guest-registration-modal"
          >
            {canClose ? (
              <CloseIcon
                data-cy-id="close-button"
                onClick={onClose}
                className={cn(
                  'absolute right-x2 top-x2 lg:text-p-black-100',
                  SplashPanel?.isDark
                    ? 'text-p-white light:text-p-black-100'
                    : 'text-p-black-100'
                )}
              />
            ) : null}

            <section>
              <PanelToRender />
            </section>
            <section className="bg-p-white">
              <ActionsPanel>
                <AuthenticationSelect
                  ticker={tickerSymbol}
                  current={type}
                  postVerifyRedirectTo={postVerifyRedirectTo}
                  prefillEmail={prefillEmail}
                  focusOnMount={focusOnMount}
                  onChangeType={(type) => setType(type)}
                  onRegisterSuccess={onRegisterSuccess}
                  onLoginSuccess={onLoginSuccess}
                />
              </ActionsPanel>
            </section>
          </div>
        </div>
      </Modal>
      {showCareersUpsell ? <CareersUpsell /> : null}
    </>
  );
};
